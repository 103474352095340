import { useNavigate } from '@remix-run/react';
import { useRef } from 'react';
import { useToggle } from 'react-use';

import { MediaFormatVersion } from '@lp-lib/media';

import { useOutsideClick } from '../hooks/useOutsideClick';
import { OrganizerRoleUtils, RoleUtils } from '../types';
import { MediaUtils } from '../utils/media';
import { CalendarIcon } from './icons/CalendarIcon';
import { CrownIcon } from './icons/CrownIcon';
import { DocumentIcon } from './icons/DocumentIcon';
import { InfoIcon } from './icons/InfoIcon';
import { MenuIcon } from './icons/MenuIcon';
import { ScoreboardIcon } from './icons/ScoreboardIcon';
import { SettingIcon } from './icons/SettingIcon';
import { TeamIcon } from './icons/TeamIcon';
import { UserIcon } from './icons/UserIcon';
import { VenueIcon } from './icons/VenueIcon';
import { MyJoyCapture } from './JoyCapture';
import {
  useMyOrganization,
  useMyOrganizer,
} from './Organization/hooks/organization';
import { LearningMenuKey, MenuKey } from './Settings/types';
import { learningSettingsPath, settingsPath } from './Settings/utils';
import { useUser } from './UserContext';
import { useVenueSlug } from './Venue/VenueProvider';

function Avatar() {
  const organizer = useMyOrganizer();

  if (organizer?.icon) {
    return (
      <img
        src={organizer.icon}
        alt=''
        className='w-10 h-10 flex-none rounded-1.5lg'
      />
    );
  }

  return (
    <MyJoyCapture
      styles={{
        size: 'w-10 h-10 flex-none',
        shape: 'rounded-1.5lg',
        text: 'text-base text-white',
      }}
      noAnimate
    />
  );
}

function OrgLogo(props: { className?: string }) {
  const organization = useMyOrganization();
  const logoSrc = MediaUtils.PickMediaUrl(organization?.logo, {
    priority: [MediaFormatVersion.SM],
  });
  if (!logoSrc) return null;

  return (
    <div
      className={`w-7.5 h-7.5 flex-none rounded-md bg-layer-002 overflow-hidden ${props.className}`}
    >
      <img
        src={logoSrc}
        alt='Organization Logo'
        className='w-full h-full object-cover'
      />
    </div>
  );
}

export type HeaderAvatarActionKey =
  | 'myEvents'
  | 'myVenue'
  | 'myMemories'
  | 'settings'
  | 'analytics'
  | 'manage-members'
  | 'logout'
  | 'resources'
  | 'assigned-to-me'
  | 'groups';

export type Action = {
  key: HeaderAvatarActionKey;
  icon: JSX.Element;
  text: string;
  onClick: (event: React.MouseEvent) => void;
};

const HeaderAvatarContainer = (props: { actions: Action[] }): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showDropdown, setShowDropdown] = useToggle(false);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useOutsideClick(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div
      ref={ref}
      className='
        z-45 bg-black hover:bg-lp-gray-002 border border-secondary rounded-xl 
        flex items-center gap-3.5 p-0.5
        relative cursor-pointer
      '
      onClick={handleToggleDropdown}
    >
      <MenuIcon className='w-3.5 h-3.5 ml-2' />

      <div className='flex items-end'>
        <OrgLogo className='relative z-5 -mr-1.5' />
        <Avatar />
      </div>

      {showDropdown && (
        <div className='w-45 absolute top-14 right-2 bg-black text-white rounded-xl p-1 text-2xs before:tooltipArrowRightTop'>
          {props.actions.map((action) => (
            <button
              key={action.key}
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                action.onClick(event);
                setShowDropdown(false);
              }}
              className='btn w-full p-3 hover:bg-lp-black-003 rounded-lg cursor-pointer select-none flex items-center justify-start'
            >
              {action.icon}
              <div className='ml-2'>{action.text}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const HeaderAvatar = (props: {
  excludeMenus?: HeaderAvatarActionKey[];
}): JSX.Element => {
  const navigate = useNavigate();
  const venueSlug = useVenueSlug();
  const user = useUser();

  const actions: Action[] = [];

  actions.push({
    key: 'myVenue',
    icon: <VenueIcon />,
    text: 'My Venue',
    onClick: () => {
      if (RoleUtils.isAdmin(user)) {
        navigate(`/host/venue/${venueSlug}`);
      } else {
        navigate(`/venue/${venueSlug}`);
      }
    },
  });

  if (user.organizer) {
    actions.push({
      key: 'myEvents',
      icon: <CalendarIcon />,
      text: 'My Events',
      onClick: () => {
        navigate('/events');
      },
    });
  }

  actions.push({
    key: 'myMemories',
    icon: <CrownIcon />,
    text: 'My Memories',
    onClick: () => {
      navigate(`/memories`);
    },
  });

  if (user.organizer) {
    actions.push({
      key: 'analytics',
      icon: <ScoreboardIcon />,
      text: 'Analytics',
      onClick: () => {
        navigate('/analytics');
      },
    });
  }

  if (user.organizer && OrganizerRoleUtils.isOwnerOrAdmin(user.organizer)) {
    actions.push({
      key: 'manage-members',
      icon: <TeamIcon className='fill-current w-3.5 h-3.5' />,
      text: 'Manage Members',
      onClick: () => {
        navigate(settingsPath(MenuKey.OrgMembers));
      },
    });
  }

  actions.push({
    key: 'resources',
    icon: <InfoIcon />,
    text: 'Resources',
    onClick: () => {
      window.open('https://intercom.help/luna-park-help-center/en/');
    },
  });

  actions.push({
    key: 'settings',
    icon: <SettingIcon />,
    text: 'Settings',
    onClick: () => {
      navigate(settingsPath());
    },
  });

  const filteredActions = actions.filter(
    (action) => !props.excludeMenus?.includes(action.key)
  );

  return <HeaderAvatarContainer actions={filteredActions} />;
};

const LearningHeaderAvatar = () => {
  const navigate = useNavigate();
  const user = useUser();

  const actions: Action[] = [];
  const isAdminOrOrgOwner = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);

  if (user.organizer && isAdminOrOrgOwner) {
    actions.push({
      key: 'assigned-to-me',
      icon: <DocumentIcon className='fill-current w-3.5 h-3.5' />,
      text: 'Assigned to Me',
      onClick: () => {
        navigate('/learning');
      },
    });
    actions.push({
      key: 'groups',
      icon: <UserIcon className='w-3.5 h-3.5' />,
      text: 'Groups',
      onClick: () => {
        navigate('/learning/admin/groups');
      },
    });
    actions.push({
      key: 'manage-members',
      icon: <TeamIcon className='fill-current w-3.5 h-3.5' />,
      text: 'Manage Members',
      onClick: () => {
        navigate(learningSettingsPath(LearningMenuKey.OrgMembers));
      },
    });
  }

  actions.push({
    key: 'settings',
    icon: <SettingIcon />,
    text: 'Settings',
    onClick: () => {
      navigate(learningSettingsPath());
    },
  });

  return <HeaderAvatarContainer actions={actions} />;
};

export {
  HeaderAvatar,
  HeaderAvatarContainer as CustomHeaderAvatar,
  LearningHeaderAvatar,
};
