import { type LearningMenuKey, type MenuKey } from './types';

export function settingsPath(menuKey?: MenuKey): string {
  if (!menuKey) return '/settings';
  return `/settings/${menuKey}`;
}

export function learningSettingsPath(menuKey?: LearningMenuKey): string {
  if (!menuKey) return '/learning/settings';
  return `/learning/settings/${menuKey}`;
}
